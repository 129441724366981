import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../References/const'
import AuthContext from '../../Store/auth-context'
import styles from './Login.module.css'

const Login = () => {
    const authCtx = useContext(AuthContext)
    const [username, setUsername] = useState('system')
    const [password, setPassword] = useState('kapassword')

    const auth = async () => {
        const authResponse = await fetch(BASE_URL_API+"/auth",
        {
            method : "POST",
            body : JSON.stringify({
                username : username,
                password : password
            })
        });

        if(!authResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const authRespo = await authResponse.json()
            if(authRespo.status==="success") {
                authCtx.login(authRespo.authToken, authRespo.adminIdentifier)
            } else {

            }
            console.log(authRespo)
        }
    }


    return (
        <div>
            <div>
                Login
            </div>
            <button onClick={() => auth()}>Now</button>

        </div>
    )
}

export default Login