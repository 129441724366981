import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/const'
import AuthContext from '../../Store/auth-context'
import styles from './AddNewOrganization.module.css'
import { useNavigate} from 'react-router-dom'

const AddNewOrganization = () => {
    const authCtx = useContext(AuthContext)
    const navigate = useNavigate()
    const [brandName, setBrandName] = useState('')
    const [legalName, setLegalName] = useState('')
    const [registeredAddress, setRegisteredAddress] = useState('')
    const [officialWebsite, setOfficialWebsite] = useState('')

    const createNewOrganization = async () => {
        const createNewOrganizationResponse = await fetch(BASE_URL_API+"/createNewOrganization",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                organizationBrandName : brandName,
                organizationLegalName : legalName, 
                organizationAddress : registeredAddress, 
                officialWebsite : officialWebsite
            })
        });

        if(!createNewOrganizationResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const createNewOrganizationRespo = await createNewOrganizationResponse.json()
            if(createNewOrganizationRespo.status==="success") {
                navigate("/organizations")
            } else {
                if(createNewOrganizationRespo.message==="token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(createNewOrganizationRespo)
        }
    }


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                <div className={styles.headerContainer}>
                    <div className={styles.headerTitle}>
                        Create A New Organization
                    </div>
                    <div className={styles.createNew}>
                        <Link to={`/organizations`}>
                            View All Organizations
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.formWrapper}>
                <div className={styles.formContainer}>
                    <div className={styles.eachFieldWrapper}>
                        <div className={styles.eachTitleWrapper}>Enter Brand Name</div>
                        <div className={styles.eachInputWrapper}>
                            <input value={brandName} autoFocus={true} onChange={(e) => setBrandName(e.target.value)}></input>
                        </div>
                    </div>
                    
                    <div className={styles.eachFieldWrapper}>
                        <div className={styles.eachTitleWrapper}>Enter Legal Name</div>
                        <div className={styles.eachInputWrapper}>
                            <input value={legalName} onChange={(e) => setLegalName(e.target.value)}></input>
                        </div>
                    </div>

                    <div className={styles.eachFieldWrapper}>
                        <div className={styles.eachTitleWrapper}>Enter registered address</div>
                        <div className={styles.eachInputWrapper}>
                            <textarea value={registeredAddress} onChange={(e) => setRegisteredAddress(e.target.value)}></textarea>
                        </div>
                    </div>

                    <div className={styles.eachFieldWrapper}>
                        <div className={styles.eachTitleWrapper}>Enter official website</div>
                        <div className={styles.eachInputWrapper}>
                            <input value={officialWebsite} onChange={(e) => setOfficialWebsite(e.target.value)}></input>
                        </div>
                    </div>


                    <div className={styles.actions}>
                        <button onClick={() => createNewOrganization()}>Submit</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddNewOrganization