import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/const'
import AuthContext from '../../Store/auth-context'
import styles from './AllOrganizations.module.css'

const AllOrganizations = () => {
    const authCtx = useContext(AuthContext)
    const [allOrganization, setAllOrganization] = useState([])
    const getAllOrganizations = async () => {
        const getAllOrganizationsResponse = await fetch(BASE_URL_API + "/getAllOrganizations",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token
                })
            });

        if (!getAllOrganizationsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllOrganizationsRespo = await getAllOrganizationsResponse.json()
            if (getAllOrganizationsRespo.status === "success") {
                setAllOrganization(getAllOrganizationsRespo.response)
            } else {
                if (getAllOrganizationsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllOrganization([])
                }
            }
            console.log(getAllOrganizationsRespo)
        }
    }

    useEffect(() => {
        getAllOrganizations()
    }, [])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                <div className={styles.headerContainer}>
                    <div>
                        All Organizations
                    </div>
                    <div className={styles.createNew}>
                        <Link to={`/organizations/add`}>
                            Add A New Organization
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.tableWrapper}>
                <div className={styles.tableContainer}>
                    <div className={styles.tableHeader}>
                        <div>
                            #
                        </div>
                        <div>
                            Brand Name
                        </div>
                        <div>
                            Legal Name
                        </div>
                        <div>
                            Registered Address
                        </div>
                    </div>
                    <div className={styles.tableContent}>
                        {allOrganization.map((item, index) => (
                            <div className={styles.eachRow} key={index}>
                                <div>
                                    {index + 1}
                                </div>
                                <div>
                                    {item?.organizationBrandName}
                                </div>
                                <div>
                                    {item?.organizationLegalName}
                                </div>
                                <div>
                                    {item?.organizationAddress}
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllOrganizations