import React, { useContext } from 'react'
import { Routes, Route } from "react-router-dom";
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Login from '../Pages/Authenticator/Login';
import MainHome from '../Pages/Home/MainHome';
import AddNewOrganization from '../Pages/Organizations/AddNewOrganization';
import AllOrganizations from '../Pages/Organizations/AllOrganizations';
import AddNewPeople from '../Pages/People/AddNewPeople';
import AllPeople from '../Pages/People/AllPeople';
import EachPeople from '../Pages/People/EachPeople';
import AuthContext from '../Store/auth-context';

import styles from './MainNavigator.module.css'

const MainNavigator = () => {
    const authCtx = useContext(AuthContext)
    let isLoggedIn = authCtx.isLoggedIn;


    console.log(authCtx)
    if (!isLoggedIn) {
        return (
            <Routes>
                <Route path="*" element={<Login />}></Route>
                <Route path="/" element={<Login />}></Route>
                <Route path="/login" element={<Login />}></Route>
            </Routes>
        )
    } else if (isLoggedIn) {
        return (
            <div>
                <div className={styles.globalWrapper}>
                    <Header />
                    <Sidebar />
                    <div className={styles.globalContentWrapper}>
                        <Routes>
                            <Route path="*" element={<MainHome />}></Route>
                            <Route path="/" element={<MainHome />}></Route>
                            <Route path="/organizations" element={<AllOrganizations />}></Route>
                            <Route path="/organizations/add" element={<AddNewOrganization />}></Route>
                            <Route path="/people" element={<AllPeople />}></Route>
                            <Route path="/people/add" element={<AddNewPeople />}></Route>
                            <Route path="/people/:employeeIdentifier" element={<EachPeople />}></Route>

                        </Routes>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <Routes>
                <Route path="*" element={<Login />}></Route>
            </Routes>
        )
    }

}

export default MainNavigator