import React from 'react'
import { Link } from 'react-router-dom';
import styles from './Sidebar.module.css'

import { FiHome, FiSearch } from "react-icons/fi";

const Sidebar = () => {
    const navigationLinks = [
        { path: "/", name: "Home" },
        { path: "/about", name: "About" },
        { path: "/settings", name: "Settings" },
    ];

    return (
        <nav className={styles.mainWrapper}>
            <div className={styles.mainHeader}>
                <div className={styles.searchIconWrapper}>
                    <FiSearch color="#C8C8C8" size={20} />
                </div>
            </div>
            <div className={styles.mainContainer}>
                {navigationLinks.map((link) => (
                    <Link to={link.path} key={link.name} className={styles.eachNav}>
                        <div>
                            <FiHome size={22} color="#E9E7E8" />
                        </div>
                        <div className={styles.navTxt}>
                            <div >{link.name}</div>
                        </div>
                    </Link>
                ))}
            </div>
            <div className={styles.mainFooter}>
                hi
            </div>
        </nav>
    );
};
export default Sidebar