import React from 'react'
import styles from './MainHome.module.css'
import { RiOrganizationChart } from "react-icons/ri";
import { FaPeopleGroup } from "react-icons/fa6";
import { IoCalendarOutline } from "react-icons/io5";
import { FaCalendarCheck } from "react-icons/fa";


import { Link } from 'react-router-dom';



const MainHome = () => {
    return (
        <div className={styles.mainWrapper}>
            <Link to={`/organizations`} className={styles.eachCardWrapper}>
                <div>
                    <RiOrganizationChart size={40} />
                </div>
                <div className={styles.navTxt}>
                    Organizations
                </div>
            </Link>
            
            <Link to={`/people`} className={styles.eachCardWrapper}>
                <div>
                    <FaPeopleGroup size={40} />
                </div>
                <div className={styles.navTxt}>
                    People
                </div>
            </Link>
            
            <Link to={`/calendar`} className={styles.eachCardWrapper}>
                <div>
                    <IoCalendarOutline size={40} />
                </div>
                <div className={styles.navTxt}>
                    Calendar
                </div>
            </Link>
           
            <Link to={`/attendances`} className={styles.eachCardWrapper}>
                <div>
                    <FaCalendarCheck size={40} />
                </div>
                <div className={styles.navTxt}>
                    Attendance
                </div>
            </Link>

        </div>
    )
}

export default MainHome