import React, { useContext } from 'react'
import AuthContext from '../Store/auth-context'
import styles from './Header.module.css'

const Header = () => {
    const authCtx = useContext(AuthContext)

    return (
        <div className={styles.mainWrapper}>
            <div>

            </div>
            <div>
                <button onClick={() => authCtx.logout()}>Logout</button>
            </div>
        </div>
    )
}

export default Header