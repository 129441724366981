import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BASE_URL_API } from '../../References/const'
import AuthContext from '../../Store/auth-context'
import styles from './AddNewPeople.module.css'

const AddNewPeople = () => {
    const authCtx = useContext(AuthContext)
    const navigate = useNavigate()
    const [fullname, setFullname] = useState('')
    const [officialEmail, setOfficialEmail] = useState('')
    const [employeeIdAtOrgLevel, setEmployeeIdAtOrgLevel] = useState('')
    const [gender, setGender] = useState('')
    const [dateOfBirth, setDateOfBirth] = useState('')
    const [designation, setDesignation] = useState('')
    const [joiningDate, setJoiningDate] = useState('')
    const [department, setDepartment] = useState('')
    const [isCreating, setIsCreating] = useState(false)

    const [deptAndDesiInfo, setDeptAndDesiInfo] = useState([
        {
            department: 'Tech',
            designation: 'Web Developer',
        },
        {
            department: 'Tech',
            designation: 'App Developer',
        },
        {
            department: 'Sales',
            designation: 'Business Development Executive',
        },
        {
            department: 'Operations',
            designation: 'Business Operation Executive'
        },
        {
            department: 'Faculty',
            designation: 'Jr. Trainer'
        },
        {
            department: 'Faculty',
            designation: 'Sr. Trainer'
        },
        {
            department: 'Faculty',
            designation: 'Head Of Department (HOD)'
        },
        {
            department: 'Operations',
            designation: 'Academic Operation Executive'
        },
        {
            department: 'Human Resources',
            designation: 'Human Resources Executive'
        },
        
    ])
    const [uniqueDepts, setUniqueDepts] = useState([])

    useEffect(() => {
        const uniqueDepartments = [...new Set(deptAndDesiInfo.map((item) => item.department))];
        setUniqueDepts(uniqueDepartments)
    }, [deptAndDesiInfo])

    const addNewPeople = async () => {
        setIsCreating(true)
        const addNewPeopleResponse = await fetch(BASE_URL_API + "/addNewPeople",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    organizationIdentifier: 'MzEzNzMwMzkzNTM0MzczNzM0MzYzMzMyMzkzMyxGSG',
                    fullname: fullname,
                    officialEmail : officialEmail,
                    employeeIdAtOrgLevel: employeeIdAtOrgLevel,
                    gender: gender,
                    dateOfBirth: dateOfBirth,
                    designation: designation,
                    joiningDate: joiningDate,
                    department: department
                })
            });

        if (!addNewPeopleResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addNewPeopleRespo = await addNewPeopleResponse.json()
            if (addNewPeopleRespo.status === "success") {
                navigate("/people")
            } else {
                if (addNewPeopleRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    alert(addNewPeopleRespo.message)
                }
            }
            console.log(addNewPeopleRespo)
        }
        setIsCreating(false)
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                <div className={styles.headerContainer}>
                    <div className={styles.headerTitle}>
                        Create A New Organization
                    </div>
                    <div className={styles.createNew}>
                        <Link to={`/people`}>
                            View All People
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.formWrapper}>
                <div className={styles.formContainer}>
                    <div className={styles.twoInRow}>
                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.eachTitleWrapper}>Enter Full Name</div>
                            <div className={styles.eachInputWrapper}>
                                <input value={fullname} autoFocus={true} onChange={(e) => setFullname(e.target.value)} />
                            </div>
                        </div>

                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.eachTitleWrapper}>Enter Employee ID</div>
                            <div className={styles.eachInputWrapper}>
                                <input value={employeeIdAtOrgLevel} onChange={(e) => setEmployeeIdAtOrgLevel(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className={styles.eachFieldWrapper}>
                        <div className={styles.eachTitleWrapper}>Official E-mail</div>
                        <div className={styles.eachInputWrapper}>
                            <input value={officialEmail} onChange={(e) => setOfficialEmail(e.target.value)} placeholder="Please enter e-mail" />
                        </div>
                    </div>

                    <div className={styles.eachFieldWrapper}>
                        <div className={styles.eachTitleWrapper}>Gender</div>
                        <div className={styles.eachOptionsWrapper}>
                            <span className={gender === "Male" ? styles.eachOptionActive : styles.eachOption} onClick={() => setGender("Male")}>Male</span>
                            <span className={gender === "Female" ? styles.eachOptionActive : styles.eachOption} onClick={() => setGender("Female")}>Female</span>
                            <span className={gender === "LGBTQ" ? styles.eachOptionActive : styles.eachOption} onClick={() => setGender("LGBTQ")}>LGBTQ</span>
                            <span className={gender === "Unknown" ? styles.eachOptionActive : styles.eachOption} onClick={() => setGender("Unknown")}>Unknown</span>
                        </div>
                    </div>


                    <div className={styles.twoInRow}>
                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.eachTitleWrapper}>Date of Birth</div>
                            <div className={styles.eachInputWrapper}>
                                <input value={dateOfBirth} type="date" onChange={(e) => setDateOfBirth(e.target.value)} />
                            </div>
                        </div>

                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.eachTitleWrapper}>Joining Date</div>
                            <div className={styles.eachInputWrapper}>
                                <input value={joiningDate} type="date" onChange={(e) => setJoiningDate(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    <div className={styles.twoInRow}>
                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.eachTitleWrapper}>Department</div>
                            <div className={styles.eachInputWrapper}>
                                <select value={department} onChange={(e) => setDepartment(e.target.value)}>
                                    <option>Select</option>
                                    {uniqueDepts?.map((item, index) => (
                                        <option key={index}>{item}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.eachTitleWrapper}>Designation</div>
                            <div className={styles.eachInputWrapper}>
                                <select value={designation} onChange={(e) => setDesignation(e.target.value)}>
                                    <option>Select</option>
                                    {deptAndDesiInfo?.filter((itemF) => itemF.department === department)?.map((item, index) => (
                                        <option key={index}>{item?.designation}</option>
                                    ))}
                                </select>
                            </div>
                        </div>


                    </div>


                    <div className={styles.createNowWrapper}>
                        {isCreating === true ?
                            <button >Creating now...</button>
                            :
                            <button onClick={() => addNewPeople()}>Create</button>
                        }
                    </div>

                </div>
            </div>
        </div>

    )
}

export default AddNewPeople