import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import GenerateOfferLetter from '../../Components/OfferLetter/GenerateOfferLetter'
import styles from './EachPeople.module.css'


const EachPeople = () => {
    const {employeeIdentifier} = useParams()
    const [isGolOpen, setIsGolOpen] = useState(false)


    const toggleGenerateOfferLetter = (k) => setIsGolOpen(k)

    return (
        <div>
            <GenerateOfferLetter isOpen={isGolOpen} toggleGenerateOfferLetter={toggleGenerateOfferLetter} />
            EachPeople
            <button onClick={() => toggleGenerateOfferLetter()}>
                Generate Offer Letter
            </button>
        </div>
    )
}

export default EachPeople