import React, { useState } from "react";

const AuthContext = React.createContext({
    token: localStorage.getItem("token"),
    isLoggedIn: false,
    adminIdentifier: localStorage.getItem("adminIdentifier"),
    login: (token) => { },
    logout: () => { }
});

export const AuthContextProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [adminIdentifier, setAdminIdentifier] = useState(localStorage.getItem('adminIdentifier'));

    const userIsLoggedIn = !!token;

    const loginHandler = (token, adminIdentifier) => {
        localStorage.setItem("token", token);
        localStorage.setItem("adminIdentifier", adminIdentifier);
        setToken(token);
        setAdminIdentifier(adminIdentifier)
    }

    const logoutHandler = () => {
        setToken(null);
        setAdminIdentifier(null)
        localStorage.removeItem("token");
        localStorage.removeItem("adminIdentifier");
    }

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        adminIdentifier: adminIdentifier,
        login: loginHandler,
        logout: logoutHandler
    }

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContext;