import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/const'
import AuthContext from '../../Store/auth-context'
import styles from './AllPeople.module.css'

const AllPeople = () => {
    const authCtx = useContext(AuthContext)
    const [allPeople, setAllPeople] = useState([])

    const getAllPeople = async () => {
        const getAllPeopleResponse = await fetch(BASE_URL_API + "/getAllPeople",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    organizationIdentifier: 'MzEzNzMwMzkzNTM0MzczNzM0MzYzMzMyMzkzMyxGSG'
                })
            });

        if (!getAllPeopleResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllPeopleRespo = await getAllPeopleResponse.json()
            if (getAllPeopleRespo.status === "success") {
                setAllPeople(getAllPeopleRespo.response)
            } else {
                if (getAllPeopleRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllPeople([])
                }
            }
            console.log(getAllPeopleRespo)
        }
    }

    useEffect(() => {
        getAllPeople()
    }, [])


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.headerWrapper}>
                <div className={styles.headerContainer}>
                    <div>
                        All People
                    </div>
                    <div className={styles.createNew}>
                        <Link to={`/people/add`}>
                            Add A New Person
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.tableWrapper}>
                <div className={styles.tableContainer}>
                    <div className={styles.tableHeader}>
                        <div className={styles.sn}>
                            #
                        </div>
                        <div className={styles.fn}>
                            Full Name
                        </div>
                        <div className={styles.dep}>
                            Department
                        </div>
                        <div className={styles.desig}>
                            Designation
                        </div>
                        <div className={styles.gender}>
                            Gender
                        </div>
                        <div className={styles.jd}>
                            Joining Date
                        </div>
                        <div className={styles.dob}>
                            Date of Birth
                        </div>
                        <div className={styles.action}>

                        </div>
                    </div>
                    <div className={styles.tableContent}>
                        {allPeople.map((item, index) => (
                            <div className={styles.eachRow} key={index}>
                                <div className={styles.sn}>
                                    {index + 1}
                                </div>
                                <div className={styles.fn}>
                                    {item?.fullname}
                                </div>
                                <div className={styles.dep}>
                                    {item?.department}
                                </div>
                                <div className={styles.desig}>
                                    {item?.designation}
                                </div>
                                <div className={styles.gender}>
                                    {item?.gender}
                                </div>
                                <div className={styles.jd}>
                                    {item?.joiningDate.substring(0, 10)}
                                </div>
                                <div className={styles.dob}>
                                    {item?.dateOfBirth.substring(0, 10)}
                                </div>
                                <div className={styles.action}>
                                    <Link to={`/people/${item?.employeeIdentifier}`}>
                                        View
                                    </Link>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllPeople